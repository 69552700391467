<template>
  <CCard>
    <!-- Header  start-->
    <CCardHeader>
      <h4 class="subhead-modal">My Tasks</h4>
    </CCardHeader>
    <!-- Header  end-->

    <!-- Body  start-->
    <CCardBody>
      <CRow class="mb-2">
        <CCol sm="10">
          <CLink
            class="card-header-action btn-minimize"
            @click="formCollapsed = !formCollapsed"
          >
            <CIcon
              size="lg"
              name="cil-chevron-double-down"
              v-if="formCollapsed"
            />
            <CIcon size="lg" name="cil-chevron-double-up" v-else />
          </CLink>
          <label><b>Search box</b></label>
        </CCol>
      </CRow>

      <CCollapse :show="formCollapsed">
        <CRow>
          <CCol>
            <CCard class="card border-secondary">
              <CCardBody>
                <CRow>
                  <CCol md="4">
                    <CSelect
                      label="Type"
                      :options="typeSelect"
                      placeholder="Please select"
                      v-model="searchObj.Type"
                      :value.sync="searchObj.Type"
                    />
                  </CCol>
                  <CCol md="4">
                    <label class="">Create Date From</label>
                    <DatePicker                      
                      class="custom-input-date"
                      :first-day-of-week="2"
                      :masks="{ input: ['YYYY/MM/DD'], data: ['YYYY/MM/DD'] }"
                      v-model="searchObj.CreateDateFrom"
                      :value.sync="searchObj.CreateDateFrom"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="bg-white border px-2 py-1 rounded"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="YYYY/MM/DD"
                          readonly
                        />
                      </template>
                    </DatePicker>
                  </CCol>
                  <CCol md="4">
                    <label class="">To</label>
                    <DatePicker
                      class="custom-input-date"
                      :first-day-of-week="2"
                      :masks="{ input: ['YYYY/MM/DD'], data: ['YYYY/MM/DD'] }"
                      v-model="searchObj.CreateDateTo"
                      :value.sync="searchObj.CreateDateTo"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="bg-white border px-2 py-1 rounded"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="YYYY/MM/DD"
                          readonly
                        />
                      </template>
                    </DatePicker>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol md="4">
                    <CInput label="Created No." v-model="searchObj.CreatedNo" />
                  </CCol>
                  <CCol md="4">
                    <CSelect
                      label="Status"
                      :options="statusSelect"
                      placeholder="Please select"
                      v-model="searchObj.Status"
                      :value.sync="searchObj.Status"
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol class="text-center">
                    <CButton color="primary" @click="SearchMyTask()">Search</CButton>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCollapse>

      <CDataTable
        :items="MyTaskData"
        :fields="fields"
        items-per-page-select
        :items-per-page="10"
        :loading="IsLoading"
        hover
        sorter
        pagination
      >
        <template #CreateNo="{item}">
          <td>
            <CLink class="text-primary" v-on:click="onClickDetail(item)">{{
              item.CreateNo
            }}</CLink>
          </td>
        </template>
        <template #ShowStatus="{item}">
          <td>
            <!-- <CBadge
              style="background-color: #FBCBE1"
              v-if="item.Status == 'Waiting for review'"
            >
              {{ item.ShowStatus }}
            </CBadge> -->
            <CBadge :color="getBadge(item.ShowStatus)">
              {{ item.ShowStatus }}
            </CBadge>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <!-- Body  end-->

    <InsightModal
      :showModal.sync="showInsightModal"
      @InsightModal-updated="showInsightModal = $event"
      :item.sync="InsightObj"
      :viewmode="viewmode"
      @save-success="PreSearchMyTask()"
    />
    <IdeaModal
      :showModal.sync="IdeaModal"
      @IdeaModal-updated="IdeaModal = $event"
      :viewmode="viewmode"
      :insightDataSelected="InsightSelected"
      @insightListSelected-updated="InsightSelected = $event"
      :item.sync="ideaObject"
      @save-success="PreSearchMyTask()"
    />
    <ConceptModal
      :showModal.sync="ConceptModal"
      @ConceptModal-updated="ConceptModal = $event"
      :item.sync="ConceptObj"
      @save-success="PreSearchMyTask()"
      :viewmode="viewmode"
    />
  </CCard>
</template>

<script>
import InsightModal from "../insight/InsightModal";
import IdeaModal from "../idea/IdeaModal";
import ConceptModal from "../concept/ConceptModal";
import { DatePicker } from "v-calendar";
import axios from "axios";
import store from "../../store";
import moment from "moment"

const fields = [
  { key: "Type", label: "Type" },
  { key: "CreateNo", label: "Create No." },
  { key: "Detail", label: "Detail" },
  { key: "ShowStatus", label: "Status" },
  { key: "CreatorName", label:"Requestor"},
  { key: "ReasonForReject", label: "Reason for reject" },
];

const typeSelect = [
  { value: "", label: "" },
  { value: "1", label: "Insight" },
  { value: "2", label: "Idea" },
  { value: "3", label: "Concept" }
];

const currentStatus = [
  { value: "", label: "" },
  { value: "1", label: "Draft" },
  { value: "2", label: "Waiting for approval" },
  { value: "3", label: "Waiting for review" }
];

// let userProfile = JSON.parse(localStorage.getItem("userprofile"));
let userProfile = JSON.parse(localStorage.getItem("userprofile"));

export default {
  name: "MyTaskTable",
  components: { InsightModal, IdeaModal, ConceptModal, DatePicker },

  data() {
    return {
      viewmode: "edit",
      fields,
      details: [],
      typeSelect,
      statusSelect:[],
      currentStatus,
      formCollapsed: true,
      showInsightModal: false,
      InsightObj: null,
      ideaObject : null,
      ConceptObj : null,
      IdeaModal: false,
      ConceptModal: false,
      createDateFrom: "",
      createDateTo: "",
      searchObj: {
        Type: "",
        CreateDateFrom: "",
        CreateDateTo: "",
        CreatedNo: "",
        Status: ""
      },
      username: userProfile.Username,
      displayName: userProfile.DisplayName,
      userRole:userProfile.Role,
      MyTaskData:[],
      IsLoading:true,
      InsightSelected: []
    };
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case "Draft":
          return "secondary";
        case "Approved":
          return "success";
        case "Waiting for review":
          return "warning";
        case "Waiting for approval":
          return "primary";
        case "Waiting for insight approval":
          return "warning";
        case "Waiting for idea approval":
          return "warning";
        case "Waiting for insight review":
          return "warning";
        case "Waiting for idea review":
          return "warning";
        case "Waiting for insight approval/review":
          return "warning";
        default:
          "primary";
      }
    },
    onClickDetail(item) {

       //console.log("item",item);
      if(item.Status == "Manager")
      {
        this.viewmode = "view"
      }

      switch (item.Type) {
        case "Insight":
          this.InsightObj = { InsightNo : item.CreateNo,InsightProcessID: item.ProcessId, InsightStatus : item.Status};
          this.showInsightModal = true;
          break;
        case "Idea":
          this.ideaObject = { IdeaNo : item.CreateNo,IdeaProcessID: item.ProcessId, IdeaStatus : item.Status};
          this.IdeaModal = true;
          break;
        case "Concept":
          this.ConceptObj = { ConceptNo : item.CreateNo,ConceptProcessID: item.ProcessId, ConceptStatus : item.Status};
          //console.log("ConceptObj",this.ConceptObj);
          this.ConceptModal = true;
          break;
      }
      
    },
    GetMyTask() {
      //console.log("GetMyTask");
      axios
        .get(store.getters.URL + "/api/MyTask/getmytaskbycreator", {
          params: {
            Username: this.username
          }
        })
        .then(res => {
          //console.log("GetMyTask",res.data);
          this.MyTaskData = res.data
          this.IsLoading = false
          // Link K2 ----------------------------------------------
          let createNo = this.$route.params.docNo || "";
          //console.log("createNo", createNo);
          if (createNo !== "") {
            let mode = this.$route.params.mode || "";
            //console.log("mode", mode);
            let item = this.MyTaskData.find(w => w.CreateNo === createNo.toUpperCase());
            //console.log("MyTaskData", this.MyTaskData);
            //console.log("item", item);
            if (item !== null && item !== undefined) {
              this.viewmode = mode;
              this.onClickDetail(item);
            }
            else
            {
              // console.log("else");
              if(mode == "view")
              {
                // console.log("mode", mode);
                this.viewmode = mode;
                item = {
                  CreateNo: createNo,
                  ProcessId: null,
                  Status: "view",
                  Type: null
                }

                if(createNo.indexOf("INS") > -1)
                {
                  item.Type = "Insight"
                }
                else if(createNo.indexOf("IDE") > -1)
                {
                  item.Type = "Idea"
                }
                else if(createNo.indexOf("CON") > -1)
                {
                  item.Type = "Concept"
                }
                else
                {
                  item.Type = ""
                }
                // console.log("item", item);
                this.onClickDetail(item);
              }
            }
          }
          // --------------------------------------------------------
        })
        .catch(err => {
          console.error(err.error);
          this.IsLoading = false
        });
    },
    PreSearchMyTask(){
      //console.log("PreSearchMyTask");
      this.viewmode = "edit";
      setTimeout(
      function() {
        this.SearchMyTask();
      }.bind(this),
      2000 // รอ processID ประมาณ 2 วิ ลองต่ำกว่านี้แล้ว processID มาไม่ทัน
    );
    },
    SearchMyTask(){
      let item = {
        Type:this.searchObj.Type,
        CreateDateFrom:moment(this.searchObj.CreateDateFrom,'YYYY-MM-DD').format('YYYY-MM-DD'),
        CreateDateTo:moment(this.searchObj.CreateDateTo,'YYYY-MM-DD').format('YYYY-MM-DD'),
        CreatedNo:this.searchObj.CreatedNo,
        Status:this.searchObj.Status,
        Username:this.username
      }
      //console.log(item)
      axios.get(store.getters.URL + '/api/MyTask/searchmytask',{
        params: item
      }).then(res => {
        //console.log("SearchMyTask",res.data)
        this.MyTaskData = res.data
        this.IsLoading = false
      }).catch(err => {
        console.error(err)
        this.IsLoading = false
      })

    },
    setStatus(){
      if(this.userRole !== "Admin"){
        this.statusSelect = this.currentStatus.filter(currentStatus => currentStatus.value !== "2")
        
      }else{
        this.statusSelect = this.currentStatus
      }
    }
  },
  mounted() {
    let createNo = this.$route.params.docNo || "";
    //console.log("createNo", createNo);
    
    this.GetMyTask();
    this.setStatus() 
  },
};
</script>
